import { reactive } from '@vue/reactivity'

// Global vars
const errors = reactive({})

//export default function useValidators() {
export const useValidators = () => {
    // Verify if text field is empty
    function isEmpty(fieldName, fieldValue) {
        errors[fieldName] = fieldValue === '' ? 'This field is required.' : ''
    }

    // Validate if valid email
    async function isEmail(fieldName, fieldValue) {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        errors[fieldName] = !pattern.test(fieldValue) ? 'Invalid ' + fieldName + ' address' : ''
    }

    // Verify if email is disposable or not deliverable or gibberish
    async function verifyEmail(fieldName, fieldValue) {
        let resp = null

        // Call email verification api
        try {
            // Check if any part of email contains satyatunes
            if (fieldValue.split('@')[1].includes('satyatunes')) {
                resp = { status: 'invalid domain' }
                resp.disposable = 'invalid'
            } else {
                resp = await $fetch(`https://api.usercheck.com/email/${fieldValue}`).then((response) => {
                    return {
                        disposable: response.disposable,
                    }
                })
            }
        } catch (error) {
            console.log('Error calling usercheck.com API = ', error)
            try {
                resp = await $fetch(`https://open.kickbox.com/v1/disposable/${fieldValue}`).then((response) => {
                    return {
                        disposable: response.disposable,
                    }
                })
            } catch (error) {
                console.log('Error calling kickbox API = ', error)
                try {
                    const options = {
                        method: 'GET',
                        headers: {
                            'X-RapidAPI-Key': '030b44fdf5msh315ce8f5e162a67p159bf5jsnb5031914af65',
                            'X-RapidAPI-Host': 'mailcheck.p.rapidapi.com',
                        },
                    }
                    resp = await $fetch(`https://mailcheck.p.rapidapi.com/?domain=${fieldValue.split('@')[1]}`, options)
                } catch (error) {
                    console.log('Error calling mailcheck rapidapi = ', error)
                    try {
                        resp = await $fetch(`https://api.eva.pingutil.com/email?email=${fieldValue}`).then(
                            (response) => {
                                return {
                                    disposable: response.data.disposable,
                                }
                            }
                        )
                    } catch (error) {
                        console.log('Error calling EVA API = ', error)
                        errors[fieldName] = 'We are having trouble validating email right now, please try later.'
                        return
                    }
                }
            }
        }

        // Check response
        if (resp && resp.disposable) {
            errors[fieldName] = 'This e-mail is not allowed, try a different e-mail.'
        } else {
            errors[fieldName] = ''
        }
    }
    return { isEmpty, isEmail, verifyEmail, errors }
}
