<script setup>
import { ref } from 'vue'

// Import the function
const { isEmpty, isEmail, errors } = useValidators()

// Vars
const email = ref('')

// Validate
function validate() {
    // Check if empty
    isEmpty('email', email.value)

    // Check if valid email pattern
    if (errors.email.length <= 0) {
        isEmail('email', email.value)
    }
}

// Reset form on mouse down and when user clicks clear button
function reset() {
    errors.email = ''
}
</script>

<template>
    <v-text-field
        type="email"
        v-model="email"
        v-bind="$attrs"
        label="Email address"
        placeholder="Enter a valid email"
        hide-details="auto"
        clearable
        :error-messages="errors.email"
        @mousedown="reset"
        @keyup="validate"
        @blur="validate"
        @click:clear="reset"
    ></v-text-field>
</template>

<style scoped></style>
